@import '/src/assets/global.scss';
@import '@angular/cdk/overlay-prebuilt.css';
@import '/src/assets/ngselect.default.theme.scss';
@import '/src/assets/ngselect.material.theme.scss';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
//@import '/node_modules/angular-calendar/css/angular-calendar.css';
//@import '/node_modules/normalize.css/normalize.css';

.selectOverlayPanel {
  width: auto !important;
}

.mobile-editor-dialog {
  max-width: unset !important;

  & > mat-dialog-container {
    @media screen and (max-width: 991.98px) {
      background-color: #fafafa !important;
      //padding: 1rem 1.5rem;

      padding: 0;

      .mat-mdc-dialog-surface {
        padding: 8px 12px;
      }
    }
  }
}

@font-face {
  font-family: 'Material Symbols Rounded';
  font-style: normal;
  font-weight: 100 400;
  src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v151/kJEPBvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzBwG-RpA6RzaxHMPdY40KH8nGzv3fzfVJO1Q.woff2) format('woff2');
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
  //font-family: Roboto, "Helvetica Neue", sans-serif;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-disabled-container-color: #fff;

  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;

  --mat-menu-item-label-text-size: 14px;

  --mdc-filled-text-field-disabled-container-color: transparent;

  .mat-form-field-appearance-fill {
    .mat-mdc-form-field-infix:not(:has(label)) {
      min-height: 18px;
      padding: .4375em 0;
    }
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #2196f3;
}

.owl-dt-container-buttons {
  color: #2196f3;
}

div.mat-mdc-dialog-content {
  padding-top: 8px !important;
}

main.page-content div.controls.footer.mobile {
  max-height: 45px;
  height: 45px;

  .right {
    max-height: 45px;
    height: 45px;
  }
}

.event-overlap-dialog-mobile.mat-mdc-dialog-panel {
  max-width: unset !important;
  
  .dialog-content {
    padding: 0 12px !important;
    display: grid;
    gap: 1em;

    .mat-icon.error {
      margin-top: 0;
    }
  }
}

.event-description-more-dialog {
  justify-content: center;
}

/* Login */
app-login-page {
  .mat-horizontal-content-container {
    padding-bottom: 0;
  }
}

/* Event Editor */
app-event-editor {
  .event-head {
    .c13 {
      .mat-mdc-form-field-error-wrapper {
        padding: 0;
      }
      
      .mat-mdc-form-field-error {
        font-size: 10px;
        margin-top: -10px;
        line-height: 10px;
      }
    }
  }
}

/* Work In Process Popup */
.work-in-process-popup {
  .mat-icon {
    width: 48px;
    font-size: 48px;
    height: 48px;
  }

  --mdc-protected-button-label-text-color: #fff !important;
  .mat-mdc-dialog-content {
    padding-bottom: 0 !important;
  }
  .mat-mdc-dialog-actions {
    margin: 0 !important;
    justify-content: center;
  }
}

/* UserData */
app-userdata .scroll-main.mobile {
  .mat-expansion-panel-header {

    span.mat-content {
      display: grid;
      align-items: center;
    }

    &.mat-expanded:has(.mat-expansion-panel-header-description) {
      padding-top: 24px;
      padding-bottom: 24px;
  
      span.mat-content {
        gap: 8px;
      }
    }
  }
}
/* UserCardFieldInput */
app-userdata usercardfield-input .mat-mdc-form-field-icon-suffix {
  display: flex;
  gap: 8px;
  padding-right: 8px;

  span {
    cursor: default;
  }
}

/* SentNotification */
app-sent-notifications {
  
  .sent-notifications-table .expanded-row .mat-mdc-form-field {
    .mdc-notched-outline__leading,
    .mdc-notched-outline__notch,
    .mdc-notched-outline__trailing {
      border: 0;
    }
  }
  
}
.filter-wrapper.sent-notifications {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
}
.filter-wrapper.payments {
  --mat-form-field-subscript-text-line-height: 8px;
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
}
.filter-footer.sent-notifications {
  padding-top: 8px;
}

/* Mobile EventEditor */
app-mobile-event-editor {
  & > .mat-mdc-card {
    border: 0 !important;
    padding: 0 !important;
  }
}

/* View UserGroup Info Dialog*/
view-user-group-info-dialog {
  .view-explanation-dialog-wrapper {
    .mat-mdc-dialog-title {
      padding-top: 16px;
    }
    .mat-mdc-dialog-content {
      padding-bottom: 0;
    }
  }
}

/* Profile Delete */
profile-delete-confirm {
  
  --mdc-filled-button-container-color: #2196f3;
  --mdc-filled-button-label-text-color: #fff;
}

/* Performance */
app-performance {
  //--mdc-icon-button-state-layer-size: 28px !important;
  //--mat-icon-button-touch-target-display: 28px !important;
}
.performance-search-period-select {
  .mat-pseudo-checkbox {
    display: none;
  }
  .mdc-button {
    border-radius: 8px;
    padding: 0 32px;
    border: 1.5px solid;
    width: 100%;

    &.selected {
      border-color: #2196f3;
    }
  }
  .mat-mdc-option > span {
    margin: auto;
    min-width: 75%;
    justify-content: center;
    display: flex;
  }
}

/* FilterBase */
.filter-details {
  label:has(mat-label.users-private-description-label) {
    min-width: 224px !important;
  }
}
.users-filterbase.private-description-tooltip .mdc-tooltip__surface {
  min-width: 500px;
}
.users-filterbase.private-description-tooltip.mobile .mdc-tooltip__surface {
  min-width: 300px;
}

/* CONTACT */
.contact-file-list-menu {
  //display: flex;

  
  .file-item {
    .mat-mdc-menu-item-text {
      display: flex;

      .file-name {
        margin-top: auto;
        margin-bottom: auto;
      }
      button {
        margin-left: auto;

        delete-icon {
          color: red;
        }
      }
    }
  }
}

/* Payments */
app-payments,
app-my-payments {
  .mat-mdc-table {
    
    th.mat-column-travelTime,
    th.mat-column-travelCostPerHour,
    th.mat-column-distance,
    th.mat-column-travelCostPerKm,
    th.mat-column-travelCost,
    th.mat-column-accuracy,
    th.mat-column-performance,
    th.mat-column-hourlyWage,
    th.mat-column-workingHours,
    th.mat-column-wage,
    th.mat-column-payment {
      padding: 0;

      .mat-sort-header-container {
        justify-content: right;

        .mat-sort-header-content {
          text-align: right;

          p.name {
            text-align: right !important;
          }
        }
      }
    }
  }
}
.mat-mdc-menu-panel:has(div.payments-summaries-menu-list),
.mat-mdc-menu-panel:has(div.performance-summaries-menu-list) {
  min-width: 300px;
  padding: 0 6px;
}
.payments-summaries-menu-list,
.performance-summaries-menu-list {
  display: grid;
  gap: 8px;

  & > div {
    line-height: 24px;
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 8px;

    .title {
      font-size: 15px;
    }

    .value {
      font-size: 15px;
    }
  }

  .summaries-row {
    justify-content: space-between;
    display: flex;
    padding: 0 16px;
  }
}

.userFilterDdl {

  &.selected {
    input {
      color: transparent !important;
    }
  }

}

/* DARK THEME SETTINGS */

/* POPUPs */
:root body:has(app-admin-root > .dark) {
  color-scheme: dark;

  // mat-menu
  --mat-menu-container-color: #4d4d4d;
  --mat-menu-item-label-text-color: #fff;

  // mat-select
  --mat-select-panel-background-color: #4d4d4d;
  --mdc-list-list-item-container-color: #4d4d4d;
  --mdc-list-list-item-label-text-color: #fff;
  --mdc-list-list-item-hover-label-text-color: #fff;
  --mdc-list-list-item-focus-label-text-color: #fff;
  --mat-option-selected-state-label-text-color: #2196f3;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #2196f3 !important;
  .mat-pseudo-checkbox.mat-pseudo-checkbox-checked::after {
    color: #007dff !important;
  }

  // mat-checkbox
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;

  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38) !important;
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-hover-icon-color: #007dff !important;
  --mdc-checkbox-selected-icon-color: #007dff !important;
  --mdc-checkbox-selected-pressed-icon-color: #007dff !important;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #007dff !important;
  --mdc-checkbox-selected-hover-state-layer-color: #007dff !important;
  --mdc-checkbox-selected-pressed-state-layer-color: #007dff !important;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-disabled-selected-icon-color: darken(#007dff, 50%);

  --mdc-switch-selected-track-color: #2196f3 !important;
  --mdc-switch-selected-hover-track-color: #2196f3 !important;

  --mat-radio-ripple-color: #2196f3 !important;
  --mdc-radio-disabled-selected-icon-color: #2196f3 !important;

  // ng-select
  .ng-select.material-theme .ng-dropdown-panel .ng-dropdown-panel-items {
    background-color: #4d4d4d;

    .ng-option-selected {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .label {
      color: #fff;
    }
  }

  .user-dropdown {
    background-color: #4d4d4d !important;
    border-color: #424242 !important;
  }

  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.85);
  --mdc-outlined-text-field-hover-outline-color: #fff;
  --mdc-icon-button-icon-color: #fff;
  --mdc-outlined-text-field-input-text-color: #fff;
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.85);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.85);
  --mdc-outlined-text-field-focus-outline-color: #2196f3;
  --mdc-outlined-text-field-focus-label-text-color: #2196f3;
  --mdc-outlined-text-field-caret-color: #fff;
  --mdc-form-field-label-text-color: #fff;

  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.6);

  /* Dialog */
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: #fff;
  --mdc-text-button-label-text-color: #fff;
  --mdc-dialog-supporting-text-color: #fff;
  --mat-select-enabled-arrow-color: #fff !important;
  --mat-select-enabled-trigger-text-color: #fff;
  --mdc-protected-button-container-color: #4d4d4d;
  --mdc-protected-button-label-text-color: #fff;

  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.38);

  /* Set Event Limits Table */
  --mat-table-background-color: #4d4d4d;

  /* Autocomplete */
  --mat-autocomplete-background-color: #4d4d4d;

  /* Owl datetime picker */
  owl-date-time-container {
    background-color: #424242;

    .owl-dt-calendar-control,
    .owl-dt-calendar-table td,
    .owl-dt-calendar-table th,
    .owl-dt-control-button-arrow,
    .owl-dt-timer-divider::before,
    .owl-dt-timer-divider::after {
      color: #fff !important;
    }

    .owl-dt-timer-input {
      background-color: transparent;
      border-color: var(--mdc-outlined-text-field-outline-color);
    }

    owl-date-time-calendar span:not(.owl-dt-control-button-content) svg,
    owl-date-time-timer svg {
      fill: #fff !important;
    }

    .owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border-color: rgba(255, 255, 255, 0.7);
    }

    .owl-dt-control-arrow-button .owl-dt-control-button-content {
      svg {
        fill: #fff !important;
      }
    }
  }

  /* Mat DateRange */
  --mat-datepicker-range-input-separator-color: #fff;

  /* Mat DatePicker */
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: #fff;
  --mat-datepicker-calendar-period-button-text-color: #fff;
  --mat-datepicker-calendar-date-text-color: #fff;
  --mat-datepicker-calendar-date-selected-state-background-color: #007dff;
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 125, 255, 0.7);
  --mat-datepicker-calendar-date-focus-state-background-color: #007dff;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: #fff;
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.25);
  .mat-calendar-period-button {
    svg {
      -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
    }
  }

  /* Slider */
  .mdc-radio.mdc-radio--disabled {
    --mat-radio-ripple-color: #424242 !important;
    --mdc-radio-disabled-selected-icon-color: #424242 !important;
  }
  .mdc-switch {
    --mdc-radio-disabled-unselected-hover-icon-color: rgba(255, 255, 255, 0.87);
    --mdc-radio-disabled-unselected-icon-color: #424242;
    --mdc-switch-unselected-icon-color: #424242;

    --mdc-switch-unselected-focus-state-layer-color: #424242;
    --mdc-switch-unselected-hover-state-layer-color: #424242;
    --mdc-switch-unselected-pressed-state-layer-color: #424242;

    --mdc-switch-unselected-handle-color: #f2f2f2;
    --mdc-switch-unselected-focus-handle-color: rgba(255, 255, 255, 0.87);
    --mdc-switch-unselected-hover-handle-color: #b5b5b5;
    --mdc-switch-unselected-pressed-handle-color: #b5b5b5;

    --mdc-switch-unselected-track-color: rgba(255, 255, 255, 0.37);
    --mdc-switch-unselected-focus-track-color: rgba(255, 255, 255, 0.37);
    --mdc-switch-unselected-hover-track-color: rgba(255, 255, 255, 0.37);
    --mdc-switch-unselected-pressed-track-color: rgba(255, 255, 255, 0.37);

    --mdc-switch-selected-focus-state-layer-color: #007dff;
    --mdc-switch-selected-hover-state-layer-color: #007dff;
    --mdc-switch-selected-pressed-state-layer-color: #007dff;

    --mdc-switch-selected-handle-color: #007dff !important;
    --mdc-switch-selected-focus-handle-color: #007dff !important;
    --mdc-switch-selected-hover-handle-color: #007dff;
    --mdc-switch-selected-pressed-handle-color: #007dff;

    --mdc-switch-selected-track-color: #2196f3 !important;
    --mdc-switch-selected-focus-track-color: #30a2ff;
    --mdc-switch-selected-hover-track-color: #30a2ff;
    --mdc-switch-selected-pressed-track-color: #30a2ff;

    &:hover {
      --mdc-switch-unselected-icon-color: #424242 !important;
    }

    .mdc-switch__handle::before {
      background: none;
    }
  }
}

/* Login Page Component*/
app-login-page {
  .dark {
    background-color: #2c2c2c;

    .login-form {
      background-color: #424242;

      #submit-login-button {
        background-color: rgba(255, 255, 255, 0.87);
        color: #2c2c2c;
      }

      .auth-password {
        gap: 8px;

        .mdc-floating-label mat-label {
          color: rgba(255, 255, 255, 0.6);
        }
  
        .mat-form-field-appearance-outline .mat-form-field-outline {
          background-color: rgba(255, 255, 255, 0.6);
        }
      }

      a.mat-mdc-button {
        color: rgba(255, 255, 255, 0.87);
      }

      .password-recovery-step button {    
        margin-top: 8px;
        background-color: rgba(255, 255, 255, 0.87);
        color: #2c2c2c;
      }

      .auth-3rdparty {
        
        asl-google-signin-button div[aria-labelledby="button-label"],
        button {
          background-color: rgba(255, 255, 255, 0.87) !important;
        }

        button {
          border-radius: 3px;
          height: 40px !important;
          width: 40px !important;
          padding: 0;
          margin-top: 0 !important;
        }
      }
    }
    --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
    --mdc-outlined-text-field-hover-outline-color: #fff;
    --mdc-icon-button-icon-color: #fff;
    --mdc-outlined-text-field-input-text-color: #fff;
    --mdc-outlined-text-field-label-text-color: #b3b3b3;
    --mdc-outlined-text-field-hover-label-text-color: #b3b3b3;
    --mdc-outlined-text-field-focus-outline-color: #2196f3;
    --mdc-outlined-text-field-focus-label-text-color: #2196f3;
    --mdc-outlined-text-field-caret-color: #fff;

    .mat-mdc-form-field {
      input:-webkit-autofill,
      input:-webkit-autofill:hover, 
      input:-webkit-autofill:focus, 
      input:-webkit-autofill:active{
          -webkit-box-shadow: 0 0 0 30px #424242 inset !important;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.87);
          transition: background-color 0s 600000s, color 0s 600000s !important;
      }
    }
  }
  
  & > div:not(.dark) {
    .mat-mdc-form-field-infix {
      background-color: #d9d9d9;
    }
    .mat-mdc-form-field-focus-overlay {
      background-color: #d9d9d9 !important;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #d9d9d9 inset !important;
    }
  }
}

/* Event Card Component */
:root .dark app-event-card .user-status mat-chip-option:not(.whiteLabel) .mat-mdc-chip-action-label {
  color: #424242 !important;
}
.light app-event-card {
  .main-info, 
  .expanded .left {
    background-color: #f5f7ff;
  }

  .resign-button {
    color: #000;
  }
}
.dark app-event-card {

  .main-info, 
  .expanded .left {
    background-color: #4d4d4d !important;
  }

  .header-container.mobile .main-info {
    background-color: #424242 !important;
  }
  .expanded.mobile .left {
    background-color: #424242 !important;
  }
  

  .details {
    .content-row .location-link a {
      color: #fff !important;
    }
    .user-status .mat-mdc-chip {
      color: #424242 !important;
    }
  }

  .event-code {
    mat-icon {
      color: #424242 !important;
    }
    color: rgba(255, 255, 255, 0.87);
  }

  .resign-button {
    color: #fff;
  }
  
}

/* Page Header */
.dark page-nav,
.dark mat-sidenav {
  .mat-toolbar {
    background-color: #303030;
  }
}

.sidenav-container {
  .mat-drawer-inner-container {
    overflow: hidden;
  }
}

/* Side Nav */

.light app-side-nav {
  mat-list-item.active {
    background-color: $light-bg-color !important;
  }
}
.dark app-side-nav {
  mat-list-item.active {
    background-color: #4d4d4d !important;
  }
}

/* FilterBase */
:root .dark filter-base {
  #search,
  #clear,
  #expand {
    background-color: #424242;
    color: #fff;

    &:not(#search):hover {
      color: #acaca4;
    }
  }
  #main-filter {
    background-color: #424242;
    color: #fff;
  }
}
:root body:has(app-admin-root > .dark) {
  .filter-details {
    background-color: #4d4d4d;
    color: #fff;

    .ng-select {
      &:not(.ng-select-focused) .ng-select-container::after {
        border-color: #b3b3b3 !important;
      }
      .ng-value-container .ng-placeholder {
        background-color: #4d4d4d;
        color: #b3b3b3;
      }
      .ng-input input,
      .ng-value,
      .ng-clear-wrapper,
      .ng-arrow-wrapper .ng-arrow {
        color: #fff;
      }

      &.ng-select-multiple {
        .ng-value {
          background-color: #007dff;
          border-radius: 8px !important;
        }
      }
    }

    .ng-dropdown-panel {
      background-color: #4d4d4d;

      .ng-dropdown-panel-items {
        background-color: #424242 !important;
        color: #fff !important;
      }
      
      .ng-option {
        background-color: #4d4d4d;
        color: #fff;

        &.ng-option-selected {
          background: rgba(0, 0, 0, 0.04);
        }
      }

      .ng-option:hover {
        background: rgba(255, 255, 255, 0.04);
      }
    }
  }

  .mat-mdc-checkbox {
    .mdc-label {
      color: #fff !important;
    }
  }

  .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: rgba(255, 255, 255, 0.38) !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    border-color: #eeeeee !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:focus:focus:not(:checked):not(:indeterminate)~.mdc-checkbox__background {
    border-color: #eeeeee !important;
  }

  .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #007dff !important;
    background-color: #007dff !important;
  }

  .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
    border-color: #007dff !important;
    background-color: #007dff !important;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: #eeeeee;
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: #007dff !important;
  }

  .mat-mdc-radio-button {
    .mdc-label {
      color: #fff !important;
    }
  }

  .option-footprint-icon {
    svg {
      -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
    }
  }

  svg.driving-license-icon,
  svg.own-shoes-icon {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
  }

  .driving-license-icon {
    color: #fff !important;
  }
}

/* Paginator */
.light app-paginator {
  .paginator-wrapper {
    .rowcount, .pagesize {
      background-color: white;
      border: 1px solid #eee;
    }
  }
}
.dark app-paginator {
  .paginator-wrapper {
    .rowcount, .pagesize {
      background-color: #2c2c2c;
      border: 1px solid #acaca4;
    }

    .pagesize .mat-mdc-select {
      line-height: 22px;
    }
  }
}

/* Ng-Select */
:root body:has(app-admin-root > .dark) {

  app-attendance,
  app-users,
  app-permissions {
    .ng-select {

      .ng-select-container {
        background-color: #303030;
        border-color: rgba(255, 255, 255, 0.12);
        color: #b3b3b3;
      }
  
      &:not(.ng-select-focused) .ng-select-container::after {
        border-color: #b3b3b3 !important;
      }
      .ng-value-container .ng-placeholder {
        color: #b3b3b3;
      }
      .ng-input input,
      .ng-value,
      .ng-clear-wrapper,
      .ng-arrow-wrapper .ng-arrow {
        color: #fff;
      }
  
      &.ng-select-multiple {
        .ng-value {
          background-color: #007dff;
          border-radius: 8px !important;
        }
      }
    }
    .ng-dropdown-panel {
      background-color: #4d4d4d;
      border-color: transparent;
  
      .ng-dropdown-panel-items {
        background-color: #424242 !important;
        color: #fff !important;
      }
      
      .ng-option {
        background-color: #4d4d4d;
        color: #fff;
  
        &.ng-option-selected,
        &.ng-option-marked,
        &.ng-option.ng-option-selected.ng-option-marked {
          background: rgba(0, 0, 0, 0.04);
          color: #fff;
        }
      }
      .ng-option:hover {
        background: rgba(255, 255, 255, 0.04);
        color: #fff;
      }
    }
  }
}

/* Events */
:root .dark .events-tab-group {
  --mdc-tab-indicator-active-indicator-color: #2196f3;
  --mat-tab-header-active-label-text-color: #2196f3;
  --mat-tab-header-active-ripple-color: #2196f3;
  --mat-tab-header-inactive-ripple-color: #2196f3;
  --mat-tab-header-active-focus-label-text-color: #2196f3;
  --mat-tab-header-active-hover-label-text-color: #2196f3;
  --mat-tab-header-active-focus-indicator-color: #2196f3;
  --mat-tab-header-active-hover-indicator-color: #2196f3;
  --mat-tab-header-inactive-label-text-color: #acaca4;
  --mat-tab-header-inactive-hover-label-text-color: #acaca4;
  --mat-tab-header-inactive-focus-label-text-color: #acaca4;
}

.light app-events-list {
  .filtered-row-counter,
  .filtered-row-counter-mobile {
    background-color: #fff;
  }
}
.dark app-events-list {
  .filtered-row-counter,
  .filtered-row-counter-mobile {
    background-color: #424242;
    .filtered-row-counter-text {
      color: #fff !important;
    }
  }
}
:root body:has(app-admin-root > .dark) {
  .confirm-report-event-menu {
    color: #fff;
  }
}
/* Event Import */
:root body:has(app-admin-root > .dark) {
  app-import-events-dialog {
    .result-bar {
      .error {
        color: #fff;
      }
    }
  }
}

/* Event Editor */
.dark app-event-editor {
  .event-summary .header-controls .statistics .users-counter-chip {
    border-color: #9facac !important;
  }

  .event-head .panel-content-wrapper ng-select.labels-ddl {
    .ng-value-container .ng-placeholder {
      background-color: #424242;
      color: #b3b3b3;
    }
    .ng-input input {
      color: #fff;
    }
    .ng-clear-wrapper {
      color: #fff;
    }
    .ng-arrow-wrapper .ng-arrow {
      color: #fff;
    }
  }

  app-user-card {
    mat-card {
      background-color: #424242 !important;
  
      &:hover, &:focus-within{
        background-color: #4d4d4d !important;
      }
    }

    .presence {
      .mat-icon {
        --mat-icon-color: #000;
        
        &.mat-icon-no-color {
          border-color: #8a8a8a !important;
        }
        &.footprint {
          background-color: #4d4d4d !important;
        }
      }
      &.forbidden .mat-icon {
        color: #fff !important
      }
    }
  }
}
:root body:has(app-admin-root > .dark) {
  /* MOBILE */
  app-mobile-event-editor {
    --mdc-dialog-container-color: #424242;
    --mdc-outlined-card-container-color: #303030;

    .mdc-card.header {
      --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.25);
      p {
        color: #fff;
      }
    } 

    .mat-mdc-select .trigger-container {
      color: #fff;
    }

    --mat-select-enabled-arrow-color: #fff !important;

    .mat-mdc-form-field .trigger > span {
      color: #fff;
    }

    ng-select.labels-ddl {
      .ng-dropdown-panel {
        background-color: #424242;
      }
      .ng-select-container:after {
        border-color: #777;
      }
      .ng-value-container .ng-placeholder {
        background-color: #303030;
        color: #b3b3b3;
      }
      .ng-value {
        background-color: #007dff !important;
        border-radius: 8px !important;
      }
      .ng-input input {
        color: #fff;
      }
      .ng-clear-wrapper {
        color: #fff;
      }
      .ng-arrow-wrapper .ng-arrow {
        color: #fff;
      }
      .ng-option {
        color: #fff !important;

        &.ng-option-selected {
          background-color: rgba(255, 255, 255, 0.12) !important;
        }
      }
    }

    .statistics app-event-statistic-chip p {
      color: #fff;
    }

    .localTimeZone-label {
      color: #fff;
    }
  }

  app-setlimits-dialog,
  app-employer-limits-dialog {
    .mat-mdc-dialog-title, 
    .mat-mdc-dialog-content, 
    .mat-mdc-dialog-actions {
      background-color: #4d4d4d !important;
    }

    .mat-mdc-dialog-title {
      margin-bottom: 0;
      padding-bottom: 16px;
    }

    .mat-mdc-table {
      th, 
      th > div,
      td > div {
        color: #fff !important;
      }
      --mdc-outlined-text-field-outline-color: #8a8a8a;
  
      th.highlighted,
      td.highlighted {
        background-color: #424242 !important;
      }

      th.highlighted {
        --mdc-outlined-text-field-outline-color: #303030;
      }

      th.applied input,
      th.reservist input {
        color: #000;
      }
    
      tbody tr:hover {
        background-color: #515151 !important;
      }
    }
  }
   

  .userFilterDdl.directadd {

    &:not(.ng-select-focused) .ng-select-container::after {
      border-color: #b3b3b3 !important;
    }

    .ng-dropdown-panel-items {
      background-color: #424242 !important;
      color: #fff !important;
    }
    .ng-option:hover()
    {
      background-color: #515151 !important;
    }
    .ng-value-container .ng-placeholder {
      background-color: #303030;
      color: #b3b3b3;
    }

    .ng-input input,
    .ng-value,
    .ng-clear-wrapper,
    .ng-arrow-wrapper .ng-arrow {
      color: #fff;
    }
  }

  .mobileHeader-menu-item.show-group {
    .show-group-icon {
      color: #fff;
    }
  }
  
}

/* Event Resign Dialog */
app-resign-dialog {
  div.mat-mdc-dialog-content {
    .fill-container {
      .mat-mdc-form-field-subscript-wrapper {
        display: none;
      }
    }

    .explanation-required-label {
      padding-top: 8px;
      padding-left: 8px;
    }
    .overlapping-events-details {
      padding-left: 24px;

      li {
        padding: 4px 0;
      }
    }
  }
  div.mat-mdc-dialog-actions {
    
    .resign-btn {
      &.mat-mdc-button-disabled {
        background-color: #ededed;
      }
    }
  }
}
:root body:has(app-admin-root > .dark) {
  app-resign-dialog {
    div.mat-mdc-dialog-actions {      
      .resign-btn {
        &.mat-mdc-button-disabled {
          background-color: #4d4d4d;
          color: #ededed;
        }
      }
    }
  }
}


/* Statistics */
:root body:has(app-admin-root > .dark) {
  app-hr-statistics-table,
  app-user-statistics-table {
    table th {
      color: #fff !important;
    }
  }
}
:root body:has(app-admin-root > .dark) app-import-dialog {
  .result-bar {
    color: #fff;
  }
}

/* Attendances */

:root body:has(app-admin-root > .dark) {

  app-attendance {
    .attendanceTable {

      tr.selected {
        & > td,
        & > td.mat-column-expand mat-icon {
          color: #303030;
        }
      }

      tr.detail-row {

        &:hover {
          background-color: transparent;

          .mat-column-expandedDetail .detail-wrapper {
            .mat-mdc-text-field-wrapper {
              background-color: transparent;
            }
            .mat-mdc-form-field-infix {
              mat-label,
              input {
                color: #fff;
              }
            }
          }
        }

        .mat-column-expandedDetail .detail-wrapper {
          .mat-mdc-text-field-wrapper {
            background-color: transparent;
          }
    
          .mat-mdc-form-field-infix {
            mat-label,
            input {
              color: #fff;
            }
          }
        }

        &.selected,
        &.selected:hover {
          
          background-color: #ffda6c;
          
          .mat-column-expandedDetail .detail-wrapper .mat-mdc-form-field-infix {
            mat-label,
              input {
                color: #303030;
              }
          }
        }
      }

    }
  }

  .ng-dropdown-panel {
    background-color: #4d4d4d;
    border-color: transparent;

    .ng-dropdown-panel-items {
      background-color: #424242 !important;
      color: #fff !important;
    }
    
    .ng-option {
      background-color: #4d4d4d;
      color: #fff;
      width: 100%;

      &.ng-option-selected,
      &.ng-option-marked,
      &.ng-option.ng-option-selected.ng-option-marked {
        background: rgba(0, 0, 0, 0.04);
        color: #fff;
      }
    }
    .ng-option:hover {
      background: rgba(255, 255, 255, 0.04);
      color: #fff;
    }
  }
}

:root body:has(app-admin-root > .dark):has(* > div.filter-details.mobile) {
  .ng-dropdown-panel .ng-option {
    background-color: #424242;
  }
}

/* Notification Creator */
:root body:has(app-admin-root > .dark) app-notification-creator {

  .header {
    background-color: #303030 !important;
  }

  .notification-head .row .list-box mat-label,
  .texteditor-container .notification-text-label {
    background-color: #303030 !important;
  }

  .texteditor-container {

    .NgxEditor__Wrapper {
      border-color: rgba(255, 255, 255, 0.38) !important;

      &.disabled {
        border-color: rgba(255, 255, 255, 0.38) !important;

        .NgxEditor {
          opacity: 0.75 !important;
        }
      }
    }

    .notification-text-label {
      color: rgba(255, 255, 255, 0.8) !important;
    }
    &:has(.NgxEditor__Wrapper.disabled) .notification-text-label {
      color: rgba(255, 255, 255, 0.8) !important;
    }

    .NgxEditor__MenuBar {
      color: #fff;
      background-color: transparent;
    }

    .NgxEditor {
      background-color: transparent;
      color: #fff;
    }

    .NgxEditor__Popup,
    .NgxEditor__MenuItem--Active,
    .NgxEditor__Dropdown--DropdownMenu {
      background-color: #424242 !important;
    }

    .NgxEditor__Dropdown:hover,
    .NgxEditor__Dropdown--Text {
      background-color: transparent;
    }
  }

  .subject-container, .notification-subject.field {
    .mat-form-field-disabled {
      label mat-label {
        color: rgba(255, 255, 255, 0.75);
      }
      .mdc-notched-outline > div {
        border-color: var(--mdc-outlined-text-field-outline-color) !important;
      }
      input {
        color: rgba(255, 255, 255, 0.75);
      }
    }
  }

  .notification-head .row .list-box {
    border-color: rgba(255, 255, 255, 0.38) !important;

    mat-label {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  .notification-head .row-section {
    border: 0 !important;
  }

  .back-button {
    border: 0 !important;
  }

  // mobile
  .notification-head.mobile .selected-users,
  .notification-head.mobile .selected-events {
    border-color: var(--mdc-outlined-text-field-outline-color) !important;

    mat-label {
      color: rgba(255, 255, 255, 0.8) !important;
      background-color: #303030 !important;
    }
  }

  .c22 {
    .mat-mdc-checkbox-disabled {
      opacity: 0.75;
    }
  }
}
:root body:has(app-admin-root > .light) app-notification-creator {
  .notification-head .list-box mat-label,
  .texteditor-container .notification-text-label {
    background-color: #fafafa !important;
  }
  // mobile
  .notification-head.mobile .selected-users,
  .notification-head.mobile .selected-events {
    mat-label {
      color: var(--mdc-outlined-text-field-hover-label-text-color) !important;
      background-color: #fafafa !important;
    }
  }
  .header {
    background-color: #fafafa !important;
  }
}
:root body:has(app-admin-root > .dark) {
  .eventUserState-filter {
    mat-pseudo-checkbox:not(.mat-pseudo-checkbox-checked) {
      border-color: #fff !important;
    }
/*
    mat-pseudo-checkbox.mat-pseudo-checkbox-checked {
      border-color: #007dff !important;
      background-color: #007dff !important;

      &::after {
        color: #fff !important;
      }
    }
    */
  }

  .notificationTemplateSelect {
    mat-icon {
      color: #fff !important;
    }
  }
}

/* Users */
:root body:has(app-admin-root > .dark) app-users {
  .mat-mdc-table {
    tr.selected {
      --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
    }
  }
}

:root body:has(app-admin-root > .dark) app-userdata,
:root body:has(app-admin-root > .dark) app-userview {
  .state-panel {
    .info-label {
      color: rgba(255, 255, 255, 0.84);
    }
  }

  .gender-radio {
    mat-label {
      color: #b3b3b3;
    }
  }

  .mdc-radio.mdc-radio--disabled {
    --mdc-radio-disabled-unselected-icon-color: #fff;
    --mat-radio-ripple-color: #fff !important;
    --mdc-radio-disabled-selected-icon-color: #fff !important;
    //--mdc-radio-disabled-selected-icon-opacity: 1;
  }

  --mdc-switch-selected-handle-color: #007dff !important;
  --mdc-switch-selected-track-color: #2196f3 !important;
  .mdc-switch {
    svg {
      fill: #fff !important;
    }

    &.mdc-switch--unselected:hover,
    &.mdc-switch--unselected:active,
    &.mdc-switch--unselected:focus {
      svg {
        fill: #303030 !important;
      }
    }
  }

  .labels .mat-mdc-card-title {

      input {
        background-color: #4d4d4d !important;
        color: #eeeeee !important;
        outline: 0;
        border: 1px solid #424242 !important;
    
        &::placeholder {
          color: #eeeeee !important;
        }
      }
  }

  .photo-wrapper .photo {
    background-color: transparent !important;
    border-color: #bbb !important;
    
    mat-icon {
      color: #bbb !important;
    }
  }

  .usercard {
    background-color: #4d4d4d !important;
  }
}
:root body:has(app-admin-root > .light) app-userdata {
  .labels .mat-mdc-card-title .title-search-field {
    border: 1px solid #ccc !important;
  }
}
:root body:has(app-admin-root > .dark) app-userview {
  input:disabled {
    color: rgba(255, 255, 255, 0.87) !important;
  }
  .phone-number-field .phone-country-selector.mat-mdc-select-disabled {
    .mat-mdc-select-value {
      color: rgba(255, 255, 255, 0.87);
    }
    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
      color: rgba(255, 255, 255, 0.54);
    }
  }
  div.info-label {
    color: rgba(255, 255, 255, 0.54);
  }
}
:root body:has(app-admin-root > .light) app-userview {
  .phone-number-field .phone-country-selector.mat-mdc-select-disabled {
    .mat-mdc-select-value {
      color: rgba(0, 0, 0, 0.87);
    }
    .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
      color: rgba(0, 0, 0, 0.54);
    }
  }
  div.info-label {
    color: rgba(0, 0, 0, 0.54);
  }
}

:root body:has(app-admin-root > .dark) show-image {
  .rotate-buttons-wrapper {
    button {
      background-color: #424242 !important;
      mat-icon {
        color: #fff !important;
      }
    }
  }
}

:root body:has(app-admin-root > .dark) {
  .mat-pseudo-checkbox-full {
    border-color: #eeeeee !important;

    &.mat-pseudo-checkbox-checked {
      border-color: #007dff !important;
      background-color: #007dff !important;
      
      &::after {
        color: #fff !important;
      }
    }
  }
}

/* User SetPassword */
:root body:has(app-admin-root > .dark) {

  user-setpassword  {
    .errors {
      color: #fff;
    }
    .password-fields .mat-icon {
      color: #fff;
    }
  }
  
  setpassword, popupsetpassword {
    .mat-mdc-dialog-actions button:not(.mat-mdc-button-disabled) {
      background-color: #007dff !important;
    }
  }
}

/* Settings */
:root body:has(app-admin-root > .dark) app-settings {
  --mdc-text-button-label-text-color: #2196f3 !important;
  mat-list-item {
    &.active-option {
      background-color: #424242 !important;
    }

    .mat-icon, .more-icon, .icon {
      color: #fff !important;
    }

    &:hover {
      background-color: #424242 !important;
      border-radius: 5px;
    }
  }

  .options mat-list-item {
    
    &:hover {
      background-color: #4d4d4d !important;
    }

    
  }

  .buttons .mat-mdc-button.mat-primary,
  .buttons .mat-mdc-button.mat-accent,
  .buttons .mat-mdc-button.mat-warn,
  .buttons .mat-mdc-button.mat-unthemed {
    --mdc-text-button-label-text-color: #fff !important;
    --mat-text-button-state-layer-color: #fff !important;
  }

  .buttons .inactive-icon-container .notification-inactive-icon {
    color: #424242 !important;
    background-color: #fff !important;
  }

  
}
/* Settings Dialogs */
:root body:has(app-admin-root > .dark) {
  app-settings-company-edit {

    .mat-mdc-card {
      background-color: #4d4d4d;

      .field {
        background-color: #4d4d4d;
      }
    }
  }

  app-settings-advertisement-edit {
    .sharing-wrapper .mat-mdc-form-field-subscript-wrapper {
      display: none;
    }
  }

  app-settings-employer-edit {
    h4 {
      color: #fff;
    }
  }

  app-settings-gdpr-link {
    .mat-mdc-text-field-wrapper {
      background-color: #424242 !important;
    }

    .setting-edit-btn {
      --mdc-text-button-label-text-color: #fff !important;
      --mat-text-button-state-layer-color: #fff !important;
    }

    --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.6);
    --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.6);
  }

  app-settings-notification-template-edit {
    .c12 .mat-mdc-slide-toggle .mdc-form-field .mdc-label {
      color: #fff;
    }

    .c41 .wrapper {

      .substitutions-button i {
        -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
      }

      .NgxEditor__Wrapper {
        border-color: rgba(255, 255, 255, 0.38) !important;
  
        &.disabled {
          border-color: rgba(255, 255, 255, 0.38) !important;
  
          .NgxEditor {
            opacity: 0.75 !important;
          }
        }
      }

      .NgxEditor__Placeholder:before {
        color: rgba(255, 255, 255, 0.6) !important;
      }
  
      .notification-text-label {
        color: rgba(255, 255, 255, 0.8) !important;
      }
      &:has(.NgxEditor__Wrapper.disabled) .notification-text-label {
        color: rgba(255, 255, 255, 0.8) !important;
      }
  
      .NgxEditor__MenuBar {
        color: #fff;
        background-color: transparent;
      }
  
      .NgxEditor {
        background-color: transparent;
        color: #fff;
      }
  
      .NgxEditor__Popup,
      .NgxEditor__MenuItem--Active,
      .NgxEditor__Dropdown--DropdownMenu {
        background-color: #424242 !important;
      }
  
      .NgxEditor__Dropdown:hover,
      .NgxEditor__Dropdown--Text {
        background-color: transparent;
      }

      .NgxEditor__Content a span {
        color: #2196f3 !important;
      }
    }

    ::-moz-selection,
        ::selection {
          background-color: #2196f3 !important;
        }
  }
}

:root body:has(app-settings-wage-code-edit) .cdk-global-overlay-wrapper {
  align-items: center !important;
}

:root body:has(app-admin-root > .light) app-settings {
  mat-list-item {
    &.active-option {
      background-color: $light-bg-color !important;
    }

    .mat-icon, .more-icon, .icon  {
      color: #000 !important;
    }
  }
}

/* Permissions */
:root body:has(app-admin-root > .dark) {
  app-permissions {

    table .mat-column-description .menuitem-name {
      color: #fff !important;
    }
  }
}

/* Performance */
:root body:has(app-admin-root > .dark) {
  .performance-summaries-menu-list {
    color: #fff;
  }
}

/* Notification Template Editor */
:root body:has(app-admin-root > .dark) {
  app-settings-notification-template-edit .text-counter {
    color: rgba(255, 255, 255, 0.87);
  }
}
:root body:has(app-admin-root > .light) {
  app-settings-notification-template-edit .text-counter {
    color: rgba(0, 0, 0, 0.87);
  }
}

/* Payments */
.cdk-overlay-pane:has(div.visible-columns-menu) {
  max-height: 80%;
  //top: 24px !important;
}
.visible-columns-menu {
  max-height: 90%;
  min-width: 200px;

  .column-row {
    padding: 8px 0;
    padding-left: 16px;

    mat-slide-toggle .mdc-form-field {
      gap: 16px;
    }
  }
}
:root body:has(app-admin-root > .dark) {

  .visible-columns-menu {
    .column-row .mdc-form-field .mdc-label {
      color: #fff;
    }
  
  }

  .payments-summaries-menu-list {
    color: #fff;
  }
}

/* Payments Import */
:root body:has(app-admin-root > .dark) {
  app-payments-import-dialog {
    .result-bar {
      color: #fff;
    }
  }
}

// mira-1366
mat-icon.state-indicator {
  font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}

.material-symbols-rounded.filled {
  font-variation-settings: 'FILL' 1, 'wght' 700, 'GRAD' 0, 'opsz' 48;
}
