/*@import '@angular/material/prebuilt-themes/deeppurple-amber.css';*/
@use '@angular/material' as mat;
@use "sass:map";

//@use '/node_modules/@angular/material/core/theming/theming';

/* OLD

@include mat.core();

@include mat.checkbox-density(-1);


// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`

//$mira-palette: ( 50 : #e7ebf0, 100 : #c3cdd9, 200 : #9bacc0, 300 : #738aa6, 400 : #557193, 500 : #375880, 600 : #315078, 700 : #2a476d, 800 : #233d63, 900 : #162d50, A100 : #8cb5ff, A200 : #5995ff, A400 : #2674ff, A700 : #0d64ff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #ffffff, A700 : #ffffff, ) );

$mira-palette: ( 50 : #e7ebf0, 100 : #c3cdd9, 200 : #9bacc0, 300 : #738aa6, 400 : #557193, 500 : #375880, 600 : #315078, 700 : #2a476d, 800 : #233d63, 900 : #162d50, A100 : #8cb5ff, A200 : #5995ff, A400 : #2674ff, A700 : #0d64ff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #ffffff, A700 : #ffffff, ) );

//#4d4d4d

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$mira-app-primary: mat.define-palette($mira-palette);
$mira-app-accent: mat.define-palette(mat.$blue-palette);

// The warn palette is optional (defaults to red).
$mira-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
//$mira-app-theme: mat.define-light-theme(( color: ( primary: $mira-app-primary, accent: $mira-app-accent, warn: $mira-app-warn, ) ));
$mira-app-theme: mat.define-dark-theme(( color: ( primary: $mira-app-primary, accent: $mira-app-accent, warn: $mira-app-warn, ) ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($mira-app-theme);
$typography: mat.define-typography-config();
@include mat.all-component-typographies($typography);


OLD */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
    

/*
$fontConfig: (
  display-4: mat.define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3: mat.define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.define-typography-level(45px, 48px, 400, 'Roboto', 0.0000em),
  display-1: mat.define-typography-level(34px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.define-typography-level(24px, 32px, 400, 'Roboto', 0.0000em),
  title: mat.define-typography-level(20px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2: mat.define-typography-level(16px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1: mat.define-typography-level(15px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.define-typography-level(14px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.define-typography-level(14px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.define-typography-level(14px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.define-typography-level(12px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px)
);
*/

// Foreground Elements

// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base:              black,
  divider:           $dark-dividers,
  dividers:          $dark-dividers,
  disabled:          $dark-disabled-text,
  disabled-button:   rgba($dark-text, 0.26),
  disabled-text:     $dark-disabled-text,
  elevation:         black,
  secondary-text:    $dark-accent-text,
  hint-text:         $dark-disabled-text,
  accent-text:       $dark-accent-text,
  icon:              $dark-accent-text,
  icons:             $dark-accent-text,
  text:              $dark-primary-text,
  slider-min:        $dark-primary-text,
  slider-off:        rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base:              $light-text,
  divider:           $light-dividers,
  dividers:          $light-dividers,
  disabled:          $light-disabled-text,
  disabled-button:   rgba($light-text, 0.3),
  disabled-text:     $light-disabled-text,
  elevation:         black,
  hint-text:         $light-disabled-text,
  secondary-text:    $light-accent-text,
  accent-text:       $light-accent-text,
  icon:              $light-text,
  icons:             $light-text,
  text:              $light-text,
  slider-min:        $light-text,
  slider-off:        rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config
// Light bg
$light-background:    #fafafa;
$light-bg-darker-5:   darken($light-background, 5%);
$light-bg-darker-10:  darken($light-background, 10%);
$light-bg-darker-20:  darken($light-background, 20%);
$light-bg-darker-30:  darken($light-background, 30%);
$light-bg-lighter-5:  lighten($light-background, 5%);
$dark-bg-tooltip:     lighten(#2c2c2c, 20%);
$dark-bg-alpha-4:     rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12:    rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
  background:               $light-background,
  status-bar:               $light-bg-darker-20,
  app-bar:                  $light-bg-darker-5,
  hover:                    $dark-bg-alpha-4,
  card:                     $light-bg-lighter-5,
  dialog:                   $light-bg-lighter-5,
  tooltip:                  $dark-bg-tooltip,
  disabled-button:          $dark-bg-alpha-12,
  raised-button:            $light-bg-lighter-5,
  focused-button:           $dark-focused,
  selected-button:          $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle:   $light-bg-darker-10,
  unselected-chip:          $light-bg-darker-10,
  disabled-list-option:     $light-bg-darker-10,
);

// Dark bg
$dark-background:     #2c2c2c;
$dark-bg-lighter-5:   lighten($dark-background, 5%);
$dark-bg-lighter-10:  lighten($dark-background, 10%);
$dark-bg-lighter-20:  lighten($dark-background, 20%);
$dark-bg-lighter-30:  lighten($dark-background, 30%);
$light-bg-alpha-4:    rgba(#fafafa, 0.04);
$light-bg-alpha-12:   rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background:               $dark-background,
  status-bar:               $dark-bg-lighter-20,
  app-bar:                  $dark-bg-lighter-5,
  hover:                    $light-bg-alpha-4,
  card:                     $dark-bg-lighter-5,
  dialog:                   $dark-bg-lighter-5,
  tooltip:                  $dark-bg-lighter-20,
  disabled-button:          $light-bg-alpha-12,
  raised-button:            $dark-bg-lighter-5,
  focused-button:           $light-focused,
  selected-button:          $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle:   $dark-bg-lighter-10,
  unselected-chip:          $dark-bg-lighter-20,
  disabled-list-option:     $dark-bg-lighter-10,
);


$mira-palette: ( 50 : #e7ebf0, 100 : #c3cdd9, 200 : #9bacc0, 300 : #738aa6, 400 : #557193, 500 : #375880, 600 : #315078, 700 : #2a476d, 800 : #233d63, 900 : #162d50, A100 : #8cb5ff, A200 : #5995ff, A400 : #2674ff, A700 : #0d64ff, contrast: ( 50 : #000000, 100 : #000000, 200 : #000000, 300 : #000000, 400 : #ffffff, 500 : #ffffff, 600 : #ffffff, 700 : #ffffff, 800 : #ffffff, 900 : #ffffff, A100 : #000000, A200 : #000000, A400 : #ffffff, A700 : #ffffff, ) );

$mira-app-primary-light: mat.define-palette($mira-palette);
$mira-app-accent-light: mat.define-palette(mat.$blue-palette);
$mira-app-warn-light: mat.define-palette(mat.$red-palette);

$mira-app-theme-light: mat.define-light-theme(( color: ( primary: $mira-app-primary-light, accent: $mira-app-accent-light, warn: $mira-app-warn-light, ) ));

// Compute font config
@include mat.core();

@include mat.checkbox-density(-1);

// Theme Config

body {
  --primary-color: #007bff; // #3d95ff
  --primary-lighter-color: #c3cdd9;
  --primary-darker-color: #2196f3; // #233d63
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}   
$mat-primary-dark: (
  main: #2196f3, //#375880
  lighter: #c3cdd9,
  darker: #2196f3, // #233d63
  200: #2196f3, // #375880 For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$mira-app-primary-dark: mat.define-palette($mat-primary-dark, main, lighter, darker); // $mira-app-primary


body {
  --accent-color: #2196f3; // #007bff
  --accent-lighter-color: #b3d7ff;
  --accent-darker-color: #005eff;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}   
$mat-accent-dark: (
  main: #2196f3, // #007bff
  lighter: #b3d7ff,
  darker: #005eff,
  200: #2196f3, // #007bff For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$mira-app-accent-dark: mat.define-palette($mat-accent-dark, main, lighter, darker); // $mira-app-accent


body {
  --warn-color: #f44336;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #f44336;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}   
$mat-warn-dark: (
  main: #f44336, // ff4545
  lighter: #ffb3b3,
  darker: #f44336,
  200: #f44336, // For slide toggle,
  contrast : (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  )
);
$mira-app-warn-dark: mat.define-palette($mat-warn-dark, main, lighter, darker); // $mira-app-warn
//$mira-app-warn: mat.define-palette(mat.$red-palette);

/*
$mira-app-theme-dark: (
  primary: $mira-app-primary,
  accent: $mira-app-accent,
  warn: $mira-app-warn,
  is-dark: true,
  foreground: $mat-dark-theme-foreground,
  background: $mat-dark-theme-background,
);
*/
$altTheme-dark: (
  primary: $mira-app-primary-dark,
  accent: $mira-app-accent-dark,
  warn: $mira-app-warn-dark,
  is-dark: false,
  foreground: $mat-light-theme-foreground,
  background: $mat-light-theme-background,
);


$mira-app-theme-dark: mat.define-dark-theme(( color: ( primary: $mira-app-primary-dark, accent: $mira-app-accent-dark, warn: $mira-app-warn-dark, ) ));

// Theme Init
//@include mat.all-component-themes($mira-app-theme-dark);


@include mat.all-component-themes($mira-app-theme-light);  
.light {
}

.dark {
  @include mat.all-component-themes($mira-app-theme-dark);
}

$typography: mat.define-typography-config();
@include mat.all-component-typographies($typography);

/*
@media (prefers-color-scheme: light) {
// Use the `-color` mixins to only apply color styles without reapplying the same
// typography and density styles.
  @include mat.all-component-themes($mira-app-theme-light);
}

.theme-alternate {
  @include mat.all-component-themes($altTheme);
}
*/

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 .65em;
  min-width: 3em;
  line-height: 36.4px
}

.mat-standard-chip {
  padding: .5em .85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: 'Material Symbols Rounded', 'Material Icons', 'Material Icons';  
  .mat-badge-content {
    font-family: 'Roboto';
  }
}





















.mat-mdc-snack-bar-container {
  background-color: #FFC000;
  color: black;
}

.filter-details {
  .mat-mdc-form-field {
    width: 100%;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-wrapper {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

#permissions-table {
  .permission-value {
    .mat-sort-header-container {
      justify-content: center !important;
      margin-left: 12px; /* a sort header által foglalt hely */
    }
  }
}

.mat-mdc-table tbody tr:hover {
  cursor: pointer;
  background: #b4b4b433;
}

mat-horizontal-stepper[hide-header] .mat-horizontal-stepper-header-container {
  display: none;
}

.mat-mdc-form-field.requiredEmpty {
  /*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
  .mat-form-field-outline {
    //color: #ffbc00;
    color: red;
  }
  .mdc-notched-outline {
    .mdc-notched-outline__leading {
      border-color: red;
    }
    .mdc-notched-outline__notch {
      border-color: red;
    }
    .mdc-notched-outline__trailing {
      border-color: red;
    }
  }
}

.fullScreenFilterDropdownOverlay {
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
}

.mat-mdc-form-field.fill-container,
.mat-form-field.fill-container .mat-form-field-infix,
.mat-form-field.fill-container .mat-form-field-flex,
.mat-form-field.fill-container .mat-form-field-wrapper {
  height: 100%;
  width: 100%;
}

.mat-mdc-form-field.fill-container textarea {
  height: calc(100% - 25px);
}

.mat-expansion-panel.event-card {
  margin-bottom: 10px;

  &.teammember {
    .mat-expansion-indicator::after {
      color: white;
    }
  }

  &.storno {
    .mat-expansion-indicator::after {
      color: white;
    }
  }
}

mat-expansion-panel {
  &.teammember {
    //TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
    .mat-form-field-label {
      color: white !important;
    }
    //TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
    .mat-form-field-outline,
    .mat-select-value,
    .mat-select-arrow {
      color: white !important;
    }
    //TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
    .mat-form-field-underline {
      background-color: white !important;
    }
    //TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.
    .mat-form-field-ripple {
      background-color: white !important;
    }
    //TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.
    .mat-checkbox-frame {
      border-color: white;
    }

    .mat-expansion-indicator::after {
      color: white;
    }
  }
}

app-userdata {
  .labels {
    /*TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
    .mat-chip-list-wrapper {
      width: 99%;
      height: fit-content;
    }
  }
}

/*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
/*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
mat-paginator.custom-labels {
  background: #fafafa;
  margin-top: 1vh;

  .mat-mdc-paginator-container {
    min-height: 20px;
  }

  .mat-mdc-paginator-range-actions {
    margin-top: -3px;
  }

  .mat-mdc-paginator-range-label {
    display: none;
  }
  /*TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version.*/
  .mat-paginator-page-size-select {
    margin: 0 4px;
  }
}

.paginator-rowcounts {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.paginator-pagesize {
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-top: 13px;
  margin-left: 15px;

  select {
    border: 1px solid #ccc;
    padding: 3px;
    border-radius: 3px;
    font-size: 12px;
  }
}

.version-mismatch-error {
  width: calc(100% - 10px);
  padding: 5px;
  top: -67px;
  position: absolute;
  transition: all ease-in-out 400ms;
  z-index: 1000;
  background-color: #FFC000;
  color: black;
  text-align: center;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.login {
    width: 100%;
  }

  &.show {
    top: 0;
  }
}

.filter-details {

  .filter-wrapper {
    font-size: 16px !important;
    font-family: Roboto,Helvetica Neue,sans-serif;
    font-weight: 400;
  }

  .ng-select-container{
    padding: 0.5em !important;
    height: 60px !important;
    margin-bottom: 8px;
    cursor: pointer !important;

    input {
      cursor: pointer !important;
    }

    .ng-value {
      font-size: 12px;
      display: flex;
      border-radius: 6px !important;
      padding-left: 10px !important;
      color: #000000de;

      .ng-value-label {
        margin: auto !important;
      }

      .ng-value-icon {
        margin: auto !important;
        padding-left: 6px !important;
      }
    }
  }

}

/*TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: #0000000a;
}

button {
  font-weight: normal;
}


::ng-deep *:not(.login-form) {
  .mat-form-field-appearance-fill {

    .mat-mdc-text-field-wrapper {
      background-color: #fff;
      padding: 0;
  
      .mat-mdc-form-field-focus-overlay {
        opacity: 100;
        background-color: #fff;
      }
    }
  }
}

::ng-deep .mat-mdc-dialog-content {
  padding-top: 8px;
}

.mat-mdc-select .mat-mdc-select-trigger,
.mat-mdc-select-panel .mat-mdc-option  {
  font-size: 14px;
}
