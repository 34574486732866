@import "/src/assets/theme-colors.scss";

html {
  height: 100%;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

body {
  min-height: 100%;
  margin: 0;

  * {
    letter-spacing: 0 !important;
  }
}

body.show-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
}

#boot-content .logobg span {
  background: url('/assets/img/mira-white.svg') center center no-repeat;
  width: 100vw;
  height: 12vh;
  display: block;
  background-size: 20vh;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
}

#boot-content .logobg {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20vh;
  border-radius: 3px;
  background-color: $primary;
}

.cdk-overlay-container {
  z-index: 1100;
}

.mat-autocomplete-panel {
  background-color: white;
  border: 1px solid #ddd;
}
.wideModal {
  width: 600px;
}

.wideModal-mobile {
  width: 600px;
  max-width: unset !important;
}

div.grecaptcha-badge {
  &.login {
    bottom: 70px !important;
  }
}

.user-export-menu {
  max-width: 360px !important;
}

button {
  font-weight: normal !important;
}