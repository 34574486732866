@mixin rtl {
  @at-root [dir="rtl"] #{&} {
        @content
    }
}

$ng-select-highlight: #3f51b5 !default;
$ng-select-primary-text: rgba(black, 0.87) !default;
$ng-select-primary-light-text: rgba(white, 0.87) !default;
$ng-select-secondary-text: rgba(black, 0.54) !default;
$ng-select-secondary-light-text: rgba(white, 0.54) !default;
$ng-select-disabled-text: rgba(black, 0.38) !default;
$ng-select-divider: rgba(black, 0.12) !default;
$ng-select-bg: #ffffff !default;

.ng-select.material-theme {
  padding-bottom: 0; //custom
  &.ng-select-disabled {
    .ng-select-container {
      &:after {
        border-bottom-color: transparent;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
        background-size: 4px 1px;
        background-repeat: repeat-x;
      }

      .ng-value-container {
        .ng-value {
          color: $ng-select-disabled-text;
        }

        .ng-placeholder {
          color: $ng-select-disabled-text;
        }
      }
    }

    .ng-arrow-wrapper .ng-arrow,
    .ng-clear-wrapper {
      color: $ng-select-disabled-text;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: $ng-select-highlight;
        border-width: 2px;
      }

      &.ng-appearance-outline {
        &:after,
        &:hover:after {
          border-color: $ng-select-highlight;
          border-width: 2px;
        }
      }

      .ng-value-container .ng-placeholder {
        transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
        color: $ng-select-highlight;
      }

      .ng-arrow-wrapper .ng-arrow {
        color: $ng-select-highlight;
      }
    }
  }

  .ng-has-value,
  &.ng-select-filtered .ng-select-container {
    .ng-placeholder {
      display: initial;
    }
  }

  .ng-has-value,
  &.ng-select-opened {
    .ng-placeholder {
      transform: translateY(-1.28125em) scale(.75) perspective(100px) translateZ(.001px);
    }
  }

  .ng-select-container {
    color: $ng-select-primary-text;
    align-items: baseline;

    &:after {
      border-bottom: thin solid rgba(0, 0, 0, .42);
      content: '';
      bottom: 0;
      left: 0;
      right: 0;
      position: absolute;
      transition: border-color .3s cubic-bezier(.55, 0, .55, .2);
    }

    &.ng-appearance-outline {
      padding: 0 .5em;
      height: 40px; // Mira

      &:after {
        border: solid 1px $ng-select-divider;
        border-radius: 5px;
        height: calc(100% - .5em);
        pointer-events: none;
        transition: border-color .3s cubic-bezier(.25, .8, .25, 1);
      }

      &:hover:after {
        border-color: $ng-select-primary-text;
        border-width: 2px;
      }

      .ng-placeholder {
        padding: 0 .25em;
        background-color: $ng-select-bg;
        z-index: 1;
      }

      .ng-value {
        padding-left: .25em;
      }
    }

    .ng-value-container {
      align-items: stretch;
      padding: .4375em 0;
      border-top: .84375em solid transparent;

      .ng-placeholder {
        position: absolute;
        color: $ng-select-secondary-text;
        transform-origin: left 0;
        transition: transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);

        @include rtl {
          transform-origin: right 0;
        }
      }

      .ng-input {
        bottom: .4375em;
        padding: 5px; // Mira
      }
    }
  }

  &.ng-select-single {
    .ng-select-container {
      &.ng-appearance-outline {
        .ng-arrow-wrapper {
          top: -12px; // Mira
        }

        .ng-clear-wrapper {
          top: -9px; // Mira
        }
      }

      .ng-arrow-wrapper {
        align-self: flex-end;
        bottom: 9px;
      }

      .ng-clear-wrapper {
        align-self: flex-end;
        bottom: 7px;
      }
    }
  }

  &.ng-select-multiple {
    &.ng-select-disabled {
      .ng-select-container .ng-value-container .ng-value {
        background-color: $ng-select-divider;
        color: rgba(0, 0, 0, .26);
      }

      .ng-appearance-outline {
        &:after,
        &:hover:after {
          background-image: none;
          border: dotted 1px $ng-select-divider;
        }
      }
    }

    .ng-select-container {
      &.ng-appearance-outline {
        &.ng-has-value {
          .ng-arrow-wrapper,
          .ng-clear-wrapper {
            border-top: none;
          }
        }

        .ng-arrow-wrapper {
          top: -5px;
        }

        .ng-clear-wrapper {
          /*top: -5px;*/
        }
      }

      .ng-value-container {
        .ng-value {
          background-color: $primary; //$ng-select-highlight;
          border-radius: 2px;
          color: $ng-select-bg;
          padding: 2px 5px;
          margin: 0 0.4375em 0.4375em 0;

          @include rtl {
            margin: 0 0 0.4375em 0.4375em;
          }

          &.ng-value-disabled {
            background-color: $ng-select-divider;
            color: rgba(0, 0, 0, .26);
          }

          .ng-value-label {
            /*font-size: 14px;
            font-weight: 500*/
          }

          .ng-value-icon {
            color: $ng-select-secondary-light-text;
            padding-right: 5px;

            @include rtl {
              padding-left: 5px;
              padding-right: 0;
            }

            &:hover {
              color: $ng-select-primary-light-text;
            }
          }
        }

        .ng-input {
          line-height: 1.375em;
        }
      }

      &.ng-has-value {
        align-items: center;

        .ng-value-container {
          padding-bottom: 0;
          padding-top: .1875em;
        }

        .ng-clear-wrapper,
        .ng-arrow-wrapper {
          border-top: .84375em solid transparent;
        }
      }
    }
  }

  .ng-clear-wrapper {
    color: $ng-select-secondary-text;

    &:hover {
      color: $ng-select-primary-text;
    }
  }

  .ng-arrow-wrapper {
    bottom: 2px;

    .ng-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 4px;
      color: $ng-select-secondary-text;
    }
  }

  .ng-spinner-zone {
    top: 3px;
  }
}

.ng-select.material-theme.material-height {
  .ng-select-container.ng-appearance-outline {
    height: unset;
  }

  .ng-select-container.ng-has-value {
    padding-bottom: 5px;
    padding-top: 4px;
  }
}

.ng-select.material-theme {
  .ng-dropdown-panel {
    background: $ng-select-bg;
    left: 0;

    &.ng-select-bottom {
      /*top: calc(100% - 1.25em);*/
      box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px $ng-select-divider;
    }

    &.ng-select-top {
      bottom: calc(100% - .84375em);
      box-shadow: 0 -5px 5px -3px rgba(0, 0, 0, .2), 0 -8px 10px 1px rgba(0, 0, 0, .14), 0 -3px 14px 2px $ng-select-divider;
    }

    &.multiple {
      .ng-option {
        &.selected {
          background: $ng-select-bg;
        }

        &.marked {
          background: rgba(0, 0, 0, .04);
        }
      }
    }

    .ng-dropdown-header {
      border-bottom: 1px solid $ng-select-divider;
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-footer {
      border-top: 1px solid $ng-select-divider;
      padding: 0 16px;
      line-height: 3em;
      min-height: 3em;
    }

    .ng-dropdown-panel-items {
      .ng-optgroup {
        user-select: none;
        cursor: pointer;
        line-height: 3em;
        height: 3em;
        padding: 0 16px;
        color: $ng-select-secondary-text;
        font-weight: 500;

        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
        }

        &.ng-option-disabled {
          cursor: default;
        }

        &.ng-option-selected {
          background: $ng-select-divider;
          color: $ng-select-highlight;
        }
      }

      .ng-option {
        line-height: 3em;
        min-height: 3em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 16px;
        text-decoration: none;
        position: relative;
        color: $ng-select-primary-text;
        text-align: left;

        @include rtl {
          text-align: right;
        }

        &.ng-option-marked {
          background: rgba(0, 0, 0, .04);
          color: $ng-select-primary-text;
        }

        &.ng-option-selected {
          background: $ng-select-divider;
          color: $ng-select-highlight;
        }

        &.ng-option-disabled {
          color: $ng-select-disabled-text;
          display: none;
        }

        &.ng-option-child {
          padding-left: 32px;

          @include rtl {
            padding-right: 32px;
            padding-left: 0
          }
        }

        .ng-tag-label {
          padding-right: 5px;

          @include rtl {
            padding-left: 5px;
            padding-right: 0;
          }

          font-size: 80%;
          font-weight: 400;
          color: $ng-select-disabled-text;
        }
      }
    }
  }
}
