@use '/node_modules/@angular/material/' as mat;
@import '/src/assets/theme.scss';

$primary: mat.get-color-from-palette($mira-app-primary-light);
$accent: mat.get-color-from-palette($mira-app-accent-light);
$warn: mat.get-color-from-palette($mira-app-warn-light);

$light-bg-color: mat.get-color-from-palette($mira-app-primary-light, 50);
$light-fg-color: mat.get-color-from-palette($mira-app-primary-light, text);

$light-text-color: mat.get-color-from-palette($mira-app-primary-light, default-contrast);
$list-hover-bg-color: mat.get-color-from-palette($mira-app-primary-light, 50);

$dark-bg-color: mat.get-color-from-palette($mat-primary-dark, darker);